import { FC, Fragment, Suspense, lazy } from 'react';
import { graphql, useFragment } from 'react-relay';

/* Helpers */
import { locations } from '../../utils/tracking/shared/favoritesTracking';
import { getMergedSbAndSponsoredItems } from '../helpers/getMergedSbAndSponsoredItems';
import { useSbSelector } from '../../reducers/useSbSelector';
import { filterFalsy } from 'dibs-ts-utils/exports/filterFalsy';
import { useServerVarsContext } from '../../global/ServerVarsContext/ServerVarsContext';

/* Components */
import { SbSharedProductAdWrapper } from '../SbSharedProductAdWrapper/SbSharedProductAdWrapper';
import { SharedItemTile } from '../../shared/SharedItemTile/SharedItemTile';
import { QuickViewProvider } from 'dibs-search-product-tile/exports/QuickViewProvider';
import { FavoritesProvider } from '../../components/global/FavoriteIcons/FavoritesProvider';
import { useMeasurementUnit } from '../useMeasurementUnit';
import { useSbSharedItemTracking } from '../hooks/sharedItemTracking/useSbSharedItemTracking';
import { SbRespPlaceholderTile } from './SbRespPlaceholderTile';

/* Constants */
import { VERTICAL_OFFSET_SB_RESP_IMG } from '../../constants/imageConstants';

import { FavoritesProviderChildrenProps } from '../../components/global/FavoriteIcons/FavoritesProviderChildrenProps';
import { SbRespSearchResultContainer_viewer$key } from './__generated__/SbRespSearchResultContainer_viewer.graphql';
import { SbRespSearchResultContainer_itemSearch$key } from './__generated__/SbRespSearchResultContainer_itemSearch.graphql';
import { SbRespSearchResultContainer_user$key } from './__generated__/SbRespSearchResultContainer_user.graphql';

import styles from './sbRespSearchResultContainer.scss';

const MerchandizingTool = lazy(
    () =>
        import(
            /* webpackChunkName: "MerchandizingTool" */ 'dibs-merchandizing-tools/exports/MerchandizingTool'
        )
);

const viewerFragment = graphql`
    fragment SbRespSearchResultContainer_viewer on Viewer {
        ...SharedItemTile_viewer
        ...useSbSharedItemTracking_viewer
    }
`;
const itemSearchFragment = graphql`
    fragment SbRespSearchResultContainer_itemSearch on ItemSearchQueryConnection {
        displayUriRef
        ...useMeasurementUnit_itemSearch
        ...useSbSharedItemTracking_itemSearch
        ...SbSharedProductAdWrapper_itemSearch
        ...SharedItemTile_itemSearch
        sponsored {
            items {
                serviceId
                isSold
                ...useSbSharedItemTracking_item
                ...SharedItemTile_item
                    @arguments(
                        isTrade: $isTrade
                        fetchVideo: $fetchTileVideo
                        priceBookName: $priceBookName
                        showSeller: $showSeller
                    )
            }
            metadata {
                itemId
                impressionTrackerLink
                clickTrackerLink
            }
        }
        edges {
            node {
                item {
                    serviceId
                    isSold
                    ...useSbSharedItemTracking_item
                    ...SharedItemTile_item
                        @arguments(
                            isTrade: $isTrade
                            fetchVideo: $fetchTileVideo
                            priceBookName: $priceBookName
                            showSeller: $showSeller
                        )
                }
            }
        }
    }
`;
const userFragment = graphql`
    fragment SbRespSearchResultContainer_user on User {
        serviceId
        hasMerchandizingPermission: hasPermission(name: "BUYER_FE_MERCH_TOOL")
        ...useMeasurementUnit_user
        ...SharedItemTile_user
    }
`;

const ITEMS_PER_ROW = 3;
const LAZY_LOAD_ITEM_TILE_INDEX = ITEMS_PER_ROW * 2;

type Props = {
    viewer: SbRespSearchResultContainer_viewer$key;
    itemSearch: SbRespSearchResultContainer_itemSearch$key;
    user: SbRespSearchResultContainer_user$key;
    showMeasurements: boolean;
    showInternalSellerName?: boolean;
    showSellerName?: boolean;
    showCreatorName?: boolean;
    showStorefrontOnlyBadge?: boolean;
};

export const SbRespSearchResultContainer: FC<Props> = ({
    viewer: viewerRef,
    itemSearch: itemSearchRef,
    user: userRef,
    showMeasurements,
    showInternalSellerName,
    showSellerName,
    showCreatorName,
    showStorefrontOnlyBadge,
}) => {
    const viewer = useFragment(viewerFragment, viewerRef);
    const itemSearch = useFragment(itemSearchFragment, itemSearchRef);
    const user = useFragment(userFragment, userRef);
    const { isGateSoldPriceVariant } = useServerVarsContext();

    const { edges, sponsored, displayUriRef } = itemSearch;

    const {
        first: pageSize = 60,
        isClient,
        isTrade,
    } = useSbSelector(state => state.relayVariables.variables);
    const refetchInFlight = useSbSelector(state => state.filters.refetchInFlight);
    const [measurementUnit] = useMeasurementUnit({ itemSearch, user });

    const userId = user?.serviceId || '';

    const { items, sponsoredItemsIndexMetadataMap } = getMergedSbAndSponsoredItems({
        displayUriRef,
        items: edges,
        sponsored,
        pageSize,
        isTrade,
        isMobile: false,
    });
    const mergedItems = (items || []).map(edge => edge?.node?.item || null).filter(filterFalsy);

    const itemIds = [...new Set(mergedItems.map(item => item?.serviceId))].filter(filterFalsy);

    const { fireItemImpressionTracking, fireItemClickTracking } = useSbSharedItemTracking({
        viewer,
        itemSearch,
        items: mergedItems,
    });

    return (
        <div className={styles.container} data-tn="search-results-container">
            {refetchInFlight ? (
                Array.from(Array(pageSize)).map((_, index) => <SbRespPlaceholderTile key={index} />)
            ) : (
                <FavoritesProvider
                    disable={!isClient}
                    itemIds={itemIds}
                    location={locations.SEARCH_BROWSE}
                    userId={userId}
                >
                    {({ props: favorites }: { props: FavoritesProviderChildrenProps }) => (
                        <QuickViewProvider>
                            <>
                                {mergedItems.map((item, index) => {
                                    const isSponsored =
                                        sponsoredItemsIndexMetadataMap &&
                                        typeof sponsoredItemsIndexMetadataMap[index] !==
                                            'undefined';

                                    const itemKey = item?.serviceId
                                        ? `${item?.serviceId}${isSponsored ? '_SPONSORED' : ''}`
                                        : index;

                                    if (refetchInFlight) {
                                        return <SbRespPlaceholderTile key={itemKey} />;
                                    }
                                    return (
                                        <Fragment key={itemKey}>
                                            <SbSharedProductAdWrapper
                                                //finding original item index
                                                index={index}
                                                itemSearch={itemSearch}
                                            />

                                            <SharedItemTile
                                                isSponsored={isSponsored}
                                                isVisuallySimilarEnabled
                                                item={item}
                                                user={user}
                                                favorites={favorites}
                                                index={index}
                                                showInternalSellerName={showInternalSellerName}
                                                showStorefrontOnlyBadge={showStorefrontOnlyBadge}
                                                showSellerName={showSellerName}
                                                showCreatorName={showCreatorName}
                                                showMeasurements={showMeasurements}
                                                lazyLoadStartIndex={LAZY_LOAD_ITEM_TILE_INDEX}
                                                imageLoadVerticalOffset={
                                                    VERTICAL_OFFSET_SB_RESP_IMG
                                                }
                                                onContentVisible={() =>
                                                    fireItemImpressionTracking({
                                                        itemId: item?.serviceId,
                                                        index,
                                                        isSponsored,
                                                    })
                                                }
                                                onClick={() =>
                                                    fireItemClickTracking({
                                                        itemId: item?.serviceId,
                                                        index,
                                                        isSponsored,
                                                    })
                                                }
                                                measurementUnit={measurementUnit}
                                                viewer={viewer}
                                                itemSearch={itemSearch}
                                                fetchFolderOnMouseOver
                                                showFavoritesTooltip={index === 0}
                                                additionalElements={
                                                    user?.hasMerchandizingPermission && (
                                                        <div
                                                            data-dibs-merchandizing-tool-item={
                                                                item?.serviceId
                                                            }
                                                        />
                                                    )
                                                }
                                                hideRecentSoldPrice={
                                                    isGateSoldPriceVariant && !user && item?.isSold
                                                }
                                            />
                                        </Fragment>
                                    );
                                })}
                                {user?.hasMerchandizingPermission && (
                                    <Suspense fallback={null}>
                                        <MerchandizingTool
                                            itemIds={itemIds}
                                            userId={userId}
                                            placement="sb"
                                            refetchInFlight={refetchInFlight}
                                        />
                                    </Suspense>
                                )}
                            </>
                        </QuickViewProvider>
                    )}
                </FavoritesProvider>
            )}
        </div>
    );
};
