import { FC } from 'react';
import { FormattedMessage } from 'dibs-react-intl';
import { graphql, useFragment } from 'react-relay';
import classnames from 'classnames';
import { BarHeader } from 'dibs-elements/exports/BarHeader';
import { filterFalsy } from 'dibs-ts-utils/exports/filterFalsy';
import { SharedItemTile } from '../../shared/SharedItemTile/SharedItemTile';
import { QuickViewProvider } from 'dibs-search-product-tile/exports/QuickViewProvider';
import { useSbSharedItemTracking } from '../hooks/sharedItemTracking/useSbSharedItemTracking';
import { useServerVarsContext } from '../../global/ServerVarsContext/ServerVarsContext';

import { SbSharedRelatedItems_itemSearch$key } from './__generated__/SbSharedRelatedItems_itemSearch.graphql';
import { SbSharedRelatedItems_viewer$key } from './__generated__/SbSharedRelatedItems_viewer.graphql';

import styles from './SbSharedRelatedItems.scss';
import dibsCss from 'dibs-css';

type Props = {
    viewer: SbSharedRelatedItems_viewer$key;
    itemSearch: SbSharedRelatedItems_itemSearch$key;
};

export const SbSharedRelatedItems: FC<Props> = ({
    viewer: viewerRef,
    itemSearch: itemSearchRef,
}) => {
    const viewer = useFragment(
        graphql`
            fragment SbSharedRelatedItems_viewer on Viewer {
                ...SharedItemTile_viewer
                ...useSbSharedItemTracking_viewer
            }
        `,
        viewerRef
    );
    const itemSearch = useFragment(
        graphql`
            fragment SbSharedRelatedItems_itemSearch on ItemSearchQueryConnection {
                ...SharedItemTile_itemSearch
                ...useSbSharedItemTracking_itemSearch
                relatedItems: youMayAlsoLike(first: 12) {
                    edges {
                        node {
                            item {
                                serviceId
                                ...SharedItemTile_item
                                    @arguments(
                                        isTrade: $isTrade
                                        priceBookName: $priceBookName
                                        showSeller: $showSeller
                                    )
                                ...useSbSharedItemTracking_item
                                ...shippingCostHelpers_item
                            }
                        }
                    }
                }
            }
        `,
        itemSearchRef
    );
    const { isMobile } = useServerVarsContext();
    const relatedItems = (itemSearch?.relatedItems?.edges || [])
        .map(item => item?.node?.item || null)
        .filter(filterFalsy);

    const { fireItemImpressionTracking, fireItemClickTracking } = useSbSharedItemTracking({
        viewer,
        itemSearch,
        items: relatedItems,
        pageType: 'related',
    });

    const title = (
        <FormattedMessage id="sb.SbSharedRelatedItems.title" defaultMessage="Related Items" />
    );

    if (relatedItems.length > 0) {
        return (
            <>
                <BarHeader title={title} />
                <QuickViewProvider>
                    <div
                        className={classnames(dibsCss.flex, dibsCss.flexWrap, dibsCss.mbSmall, {
                            [styles.container]: isMobile,
                        })}
                        data-tn="related-items"
                    >
                        {relatedItems.map((relatedItem, index) => {
                            if (relatedItem) {
                                return (
                                    <SharedItemTile
                                        user={null}
                                        key={index}
                                        index={index}
                                        item={relatedItem}
                                        showSellerName={false}
                                        viewer={viewer}
                                        itemSearch={itemSearch}
                                        onContentVisible={() => {
                                            fireItemImpressionTracking({
                                                itemId: relatedItem.serviceId,
                                                index,
                                            });
                                        }}
                                        onClick={() =>
                                            fireItemClickTracking({
                                                itemId: relatedItem.serviceId,
                                                index,
                                            })
                                        }
                                    />
                                );
                            } else {
                                return null;
                            }
                        })}
                    </div>
                </QuickViewProvider>
            </>
        );
    }
    return null;
};
