/**
 * @generated SignedSource<<eaa2a59c5083c32dd979f4c7ffb61104>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type VerticalType = "art" | "fashion" | "furniture" | "jewelry" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type SbRespMainContent_itemSearch$data = {
  readonly displayMaxNumberOfPages: number | null;
  readonly displayUriRef: string | null;
  readonly pageType: string | null;
  readonly topCategoryL1: VerticalType | null;
  readonly " $fragmentSpreads": FragmentRefs<"SbRespAppliedFilters_itemSearch" | "SbRespBrowseHeader_itemSearch" | "SbRespPageSize_itemSearch" | "SbRespRefineMenuSelector_itemSearch" | "SbRespSearchResultContainer_itemSearch" | "SbRespSortItems_itemSearch" | "SbSharedRecentlySoldItems_itemSearch" | "SbSharedRelatedItems_itemSearch" | "SharedFollowTopButton_itemSearch">;
  readonly " $fragmentType": "SbRespMainContent_itemSearch";
};
export type SbRespMainContent_itemSearch$key = {
  readonly " $data"?: SbRespMainContent_itemSearch$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbRespMainContent_itemSearch">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbRespMainContent_itemSearch",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "topCategoryL1",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayUriRef",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "pageType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "displayMaxNumberOfPages",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbRespRefineMenuSelector_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbRespSortItems_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbRespPageSize_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbRespBrowseHeader_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbRespSearchResultContainer_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRelatedItems_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbSharedRecentlySoldItems_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SbRespAppliedFilters_itemSearch"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SharedFollowTopButton_itemSearch"
    }
  ],
  "type": "ItemSearchQueryConnection",
  "abstractKey": null
};

(node as any).hash = "3694f1efa1cd81bcdb329cee460e8b36";

export default node;
