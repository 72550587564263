/**
 * @generated SignedSource<<d177dcade59732a8ebad0c014815210a>>
 * @relayHash 85052b1ed5f31394c73726967ea976ea
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID app-buyer-finding/483.0.0-2024-10-09T12:58:41.689Z/SbRespNullSearchResultsTilesLoaderQuery

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbRespNullSearchResultsTilesLoaderQuery$variables = {
  showModules: boolean;
};
export type SbRespNullSearchResultsTilesLoaderQuery$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"CategoryTiles_viewer">;
  };
};
export type SbRespNullSearchResultsTilesLoaderQuery = {
  response: SbRespNullSearchResultsTilesLoaderQuery$data;
  variables: SbRespNullSearchResultsTilesLoaderQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "showModules"
  }
],
v1 = {
  "kind": "Literal",
  "name": "categoryLevels",
  "value": 2
},
v2 = {
  "kind": "Literal",
  "name": "numItems",
  "value": 2
},
v3 = {
  "kind": "Literal",
  "name": "trendType",
  "value": "CATEGORY"
},
v4 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "displayName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "url",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "image",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SbRespNullSearchResultsTilesLoaderQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CategoryTiles_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SbRespNullSearchResultsTilesLoaderQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "condition": "showModules",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": "trendingFurniture",
                "args": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  {
                    "kind": "Literal",
                    "name": "parentCategory",
                    "value": "FURNITURE"
                  },
                  (v3/*: any*/)
                ],
                "concreteType": "TrendingModules",
                "kind": "LinkedField",
                "name": "trending",
                "plural": true,
                "selections": (v4/*: any*/),
                "storageKey": "trending(categoryLevels:2,numItems:2,parentCategory:\"FURNITURE\",trendType:\"CATEGORY\")"
              },
              {
                "alias": "trendingArt",
                "args": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  {
                    "kind": "Literal",
                    "name": "parentCategory",
                    "value": "ART"
                  },
                  (v3/*: any*/)
                ],
                "concreteType": "TrendingModules",
                "kind": "LinkedField",
                "name": "trending",
                "plural": true,
                "selections": (v4/*: any*/),
                "storageKey": "trending(categoryLevels:2,numItems:2,parentCategory:\"ART\",trendType:\"CATEGORY\")"
              },
              {
                "alias": "trendingFashion",
                "args": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  {
                    "kind": "Literal",
                    "name": "parentCategory",
                    "value": "FASHION"
                  },
                  (v3/*: any*/)
                ],
                "concreteType": "TrendingModules",
                "kind": "LinkedField",
                "name": "trending",
                "plural": true,
                "selections": (v4/*: any*/),
                "storageKey": "trending(categoryLevels:2,numItems:2,parentCategory:\"FASHION\",trendType:\"CATEGORY\")"
              },
              {
                "alias": "trendingJewelry",
                "args": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  {
                    "kind": "Literal",
                    "name": "parentCategory",
                    "value": "JEWELRY"
                  },
                  (v3/*: any*/)
                ],
                "concreteType": "TrendingModules",
                "kind": "LinkedField",
                "name": "trending",
                "plural": true,
                "selections": (v4/*: any*/),
                "storageKey": "trending(categoryLevels:2,numItems:2,parentCategory:\"JEWELRY\",trendType:\"CATEGORY\")"
              }
            ]
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "app-buyer-finding/483.0.0-2024-10-09T12:58:41.689Z/SbRespNullSearchResultsTilesLoaderQuery",
    "metadata": {},
    "name": "SbRespNullSearchResultsTilesLoaderQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "401813d1921dab01e128b3597f448a73";

export default node;
