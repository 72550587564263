import { FC } from 'react';
import { useFragment, graphql } from 'react-relay';

import { SharedPagination } from '../../shared/SharedPagination/SharedPagination';
import { SbRespRefineMenuSelector } from '../SbRespRefineMenuSelector/SbRespRefineMenuSelector';
import { SbRespSortItems } from '../SbRespSortItems/SbRespSortItems';
import { SbRespPageSize } from '../SbRespPageSize/SbRespPageSize';
import { SbRespBrowseHeader } from '../SbRespBrowseHeader/SbRespBrowseHeader';
import { SbRespAppliedFilters } from '../SbRespAppliedFilters/SbRespAppliedFilters';
import { SbRespSearchResultContainer } from '../SbRespSearchResultContainer/SbRespSearchResultContainer';
import { SbSharedRelatedItems } from '../SbSharedRelatedItems/SbSharedRelatedItems';
import { SbSharedRecentlySoldItems } from '../SbSharedRecentlySoldItems/SbSharedRecentlySoldItems';
import { SharedFollowTopButton } from '../../shared/SharedFollowTopButton/SharedFollowTopButton';

import { useSbSelector } from '../../reducers/useSbSelector';
import { VERTICALS } from '../../constants/uriConstants';
import { pageTypeConstants as pageTypes } from '../../constants/pageTypeConstants';

import classnames from 'classnames';
import dibsCss from 'dibs-css';

import { SbRespMainContent_itemSearch$key } from './__generated__/SbRespMainContent_itemSearch.graphql';
import { SbRespMainContent_viewer$key } from './__generated__/SbRespMainContent_viewer.graphql';
import { SbRespMainContent_user$key } from './__generated__/SbRespMainContent_user.graphql';

const viewerFragment = graphql`
    fragment SbRespMainContent_viewer on Viewer {
        ...SbRespRefineMenuSelector_viewer
        ...SbRespSearchResultContainer_viewer
        ...SbSharedRelatedItems_viewer
        ...SbSharedRecentlySoldItems_viewer
    }
`;

const userFragment = graphql`
    fragment SbRespMainContent_user on User {
        canShowSellerName
        canShowInternalSellerName: hasPermission(name: "VIEW_SELLER_NAME")
        canShowStorefrontOnlyBadge: hasPermission(name: "VIEW_STOREFRONT_ONLY_BADGE")
        ...SbRespRefineMenuSelector_user
        ...SbRespSearchResultContainer_user
        ...SbRespAppliedFilters_user
        ...SbRespSortItems_user
        ...SharedFollowTopButton_user
    }
`;

const itemSearchFragment = graphql`
    fragment SbRespMainContent_itemSearch on ItemSearchQueryConnection {
        topCategoryL1
        displayUriRef
        pageType
        displayMaxNumberOfPages
        ...SbRespRefineMenuSelector_itemSearch
        ...SbRespSortItems_itemSearch
        ...SbRespPageSize_itemSearch
        ...SbRespBrowseHeader_itemSearch
        ...SbRespSearchResultContainer_itemSearch
        ...SbSharedRelatedItems_itemSearch
        ...SbSharedRecentlySoldItems_itemSearch
        ...SbRespAppliedFilters_itemSearch
        ...SharedFollowTopButton_itemSearch
    }
`;

type Props = {
    currency: string;
    itemSearch: SbRespMainContent_itemSearch$key;
    viewer: SbRespMainContent_viewer$key;
    user: SbRespMainContent_user$key;
    isClient?: boolean;
    isThinPage: boolean;
    hideSaveSearchButton?: boolean;
};

export const SbRespMainContent: FC<Props> = ({
    currency,
    itemSearch: itemSearchRef,
    viewer: viewerRef,
    user: userRef,
    isClient,
    isThinPage,
    hideSaveSearchButton,
}) => {
    const { showMeasurements, generatedUriRef, page } = useSbSelector(
        ({ displayPreferences, filters }) => ({
            showMeasurements: displayPreferences.showMeasurements,
            generatedUriRef: filters.generatedUriRef,
            page: filters.page,
        })
    );
    const viewer = useFragment(viewerFragment, viewerRef);
    const user = useFragment(userFragment, userRef);
    const itemSearch = useFragment(itemSearchFragment, itemSearchRef);

    const { topCategoryL1, displayUriRef, displayMaxNumberOfPages, pageType } = itemSearch;

    const { canShowInternalSellerName, canShowSellerName, canShowStorefrontOnlyBadge } = user || {};

    const isFurniture = topCategoryL1 === VERTICALS.FURNITURE;
    const canShowCreatorName = !canShowSellerName && isFurniture;
    const sharedProps = {
        currency,
        itemSearch,
        viewer,
        user,
        isClient,
    };

    return (
        <div className={classnames(dibsCss.flex, dibsCss.flexNowrap)}>
            <SbRespRefineMenuSelector
                {...sharedProps}
                uriRef={generatedUriRef || ''}
                isThinPage={isThinPage}
            />
            <div className={classnames(dibsCss.flexGrow, dibsCss.minW1px)}>
                <div
                    className={classnames(
                        dibsCss.flex,
                        dibsCss.justifyBetween,
                        dibsCss.flexRowReverse,
                        dibsCss.mbLarge,
                        dibsCss.gapSmall
                    )}
                >
                    <div className={classnames(dibsCss.flex, dibsCss.justifyEnd, dibsCss.itemsEnd)}>
                        <SbRespSortItems itemSearch={itemSearch} user={user} />
                        <SbRespPageSize itemSearch={itemSearch} />
                    </div>
                    <div className={hideSaveSearchButton ? dibsCss.myAuto : ''}>
                        <SbRespBrowseHeader itemSearch={itemSearch} />
                        {!hideSaveSearchButton && (
                            <SharedFollowTopButton itemSearch={itemSearch} user={user} />
                        )}
                    </div>
                </div>
                <SbRespAppliedFilters user={user} currency={currency} itemSearch={itemSearch} />
                <div className={classnames(dibsCss.flexGrow, dibsCss.minW1px)}>
                    {/* Item Results */}
                    <SbRespSearchResultContainer
                        {...sharedProps}
                        showInternalSellerName={!!canShowInternalSellerName}
                        showStorefrontOnlyBadge={
                            !!canShowStorefrontOnlyBadge && pageType === pageTypes.DEALER
                        }
                        showSellerName={!!canShowSellerName}
                        showCreatorName={canShowCreatorName}
                        showMeasurements={showMeasurements}
                    />
                    {/* Related Items */}
                    {isThinPage && (
                        <>
                            <SbSharedRelatedItems viewer={viewer} itemSearch={itemSearch} />
                            <SbSharedRecentlySoldItems viewer={viewer} itemSearch={itemSearch} />
                        </>
                    )}

                    {/* Pagination */}
                    <div className={classnames(dibsCss.my0, dibsCss.mxAuto)}>
                        <SharedPagination
                            currentPage={page}
                            numberOfPages={displayMaxNumberOfPages ?? 0}
                            displayUriRef={displayUriRef || ''}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};
