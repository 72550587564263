/**
 * @generated SignedSource<<d15ad1105cc83980b7c35527c5138fca>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SbRespSearchResultContainer_user$data = {
  readonly hasMerchandizingPermission: boolean | null;
  readonly serviceId: string | null;
  readonly " $fragmentSpreads": FragmentRefs<"SharedItemTile_user" | "useMeasurementUnit_user">;
  readonly " $fragmentType": "SbRespSearchResultContainer_user";
};
export type SbRespSearchResultContainer_user$key = {
  readonly " $data"?: SbRespSearchResultContainer_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"SbRespSearchResultContainer_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SbRespSearchResultContainer_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "serviceId",
      "storageKey": null
    },
    {
      "alias": "hasMerchandizingPermission",
      "args": [
        {
          "kind": "Literal",
          "name": "name",
          "value": "BUYER_FE_MERCH_TOOL"
        }
      ],
      "kind": "ScalarField",
      "name": "hasPermission",
      "storageKey": "hasPermission(name:\"BUYER_FE_MERCH_TOOL\")"
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "useMeasurementUnit_user",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UserPreferences",
          "kind": "LinkedField",
          "name": "preferences",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "measurementUnit",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SharedItemTile_user"
    }
  ],
  "type": "User",
  "abstractKey": null
};

(node as any).hash = "ba9aa42d4811a8950a05b94032e6ea7f";

export default node;
